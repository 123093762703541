define("adtikir3v1/models/ad-campaign-selection", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model,
    belongsTo
    // hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    campaignName: attr('string'),
    campaignNameLocal: attr('string'),
    //
    campaignPic: attr('string'),
    adCampaign: belongsTo('ad-campaign', {
      async: true
    }),
    updatedAt: attr('date')
  });
});