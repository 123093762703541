define("adtikir3v1/pods/components/adk-image-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rDk/Othg",
    "block": "{\"symbols\":[\"modelImage\",\"index\"],\"statements\":[[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"flex adk-imgLayout layout-row layout-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",false],[12,\"class\",[29,[\"modelImage \",[28,\"active-class\",[[23,1,[\"id\"]],[24,[\"selectedItem\",\"id\"]]],null],\" \\n        adk-imgList\"]]],[3,\"action\",[[23,0,[]],\"selectImage\",[28,\"get\",[[23,1,[]],[24,[\"imageName\"]]],null],[23,2,[]],[23,1,[]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"get\",[[23,1,[]],[24,[\"imageName\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"figure\",true],[8],[0,\"\\n\"],[0,\"          \"],[5,\"adk-display-image\",[],[[\"@fileid\",\"@width\",\"@height\"],[[28,\"get\",[[23,1,[]],[24,[\"imageName\"]]],null],104,104]]],[0,\"\\n          \"],[7,\"figcaption\",true],[10,\"class\",\"adk-figCaption\"],[8],[1,[28,\"get\",[[23,1,[]],[24,[\"description\"]]],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adtikir3v1/pods/components/adk-image-selector/template.hbs"
    }
  });
});