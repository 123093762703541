define("adtikir3v1/pods/template/new/ad/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    templateType: '',
    init() {
      this._super(...arguments);
      this.set('adCampaignSelect', '');
      this.set('adSelect', '');
      this.set('adCampaignOptionsArray', this.findAdCampaign.perform());
      this.set('adOptionsArray', []);
    },
    // need write function to load campaign 
    findAdCampaign: (0, _emberConcurrency.task)(function* () {
      let result = yield this.store.findAll('ad-campaign-selection');
      return result;
    }),
    findAdSelection: (0, _emberConcurrency.task)(function* (adCampaignId) {
      let result = yield this.store.query('ad-selection', {
        adCampaignId: adCampaignId
      });
      return result;
    }),
    loadAd: (0, _emberConcurrency.task)(function* (adSelection) {
      this.set('ad', yield adSelection.get('ad'));
    }),
    actions: {
      setAdCampaign(adCampaignSelection) {
        this.set('adCampaignSelect', adCampaignSelection);
        this.set('adSelect', '');
        this.set('adOptionsArray', this.findAdSelection.perform(adCampaignSelection.adCampaign.get('id')));
      },
      selectAdSelection(adSelection) {
        this.set('adSelect', adSelection);
        this.loadAd.perform(adSelection);
      }
    } //actions
  });
});