define("adtikir3v1/pods/template/new/route", ["exports", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import { task} from 'ember-concurrency';
  //route for campaign.index (listing route)
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service,
    adkSessionAccount: Ember.inject.service
  });
});