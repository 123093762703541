define("adtikir3v1/pods/template/new/ad/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    beforeModel() {
      let newController = this.controllerFor("template.new");
      if (newController.templateType !== "ad") {
        this.transitionTo("template.new");
      }
    },
    model() {
      try {
        let mediaTypes = this.store.findAll("mediaType");
        return {
          mediaTypes: mediaTypes
        };
      } catch (e) {
        throw e;
      }
    }
  });
});