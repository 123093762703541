define("adtikir3v1/pods/components/adkpanel-adpost-discussion/component", ["exports", "ember-concurrency", "resemblejs/resemble"], function (_exports, _emberConcurrency, _resemble) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function onComplete(data) {
    let diffImage = new Image();
    diffImage.src = data.getImageDataUrl();
    let compareCompareDialogContent = document.getElementById("compareImageDialogContentId");
    compareCompareDialogContent.removeChild(compareCompareDialogContent.firstChild);
    compareCompareDialogContent.appendChild(diffImage);

    // if (data.misMatchPercentage == 0) {
    //     //$("#thesame").show();
    //     //$("#diff-results").hide();
    // } else {
    //     //$("#mismatch").text(data.misMatchPercentage);
    //     if (!data.isSameDimensions) {
    //         //$("#differentdimensions").show();
    //     } else {
    //        // $("#differentdimensions").hide();
    //     }
    //     //$("#diff-results").show();
    //     //$("#thesame").hide();
    // }
  } //onComplete
  var _default = _exports.default = Ember.Component.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    page: 0,
    pageSize: 20,
    //formInvalid: false,
    newTitle: "",
    newComment: "",
    newMedia: "",
    compareArray: null,
    showCompareImageDialog: false,
    // totalPage: computed('total', 'pageSize', function(){
    //     let total = 0;
    //     if(this.total) {
    //         total = this.total;
    //     }
    //     let pageSize = 1;
    //     if(this.pageSize > 0) {
    //         pageSize = this.pageSize;
    //     }
    //     total = Math.ceil(total / pageSize);
    //     return total;
    // }),

    totalPage: Ember.computed("total", "pageSize", function () {
      let total = this.get("total");
      let pageSize = 1;
      if (this.pageSize > 0) {
        pageSize = this.pageSize;
      }
      total = Math.ceil(total / pageSize);
      return total;
    }),
    pageArray: Ember.computed("totalPage", function () {
      let totalPage = this.get("totalPage");
      if (totalPage == 0) return [];
      //we need to generate an array from 1 to x as the
      return Array.from(Array(totalPage).keys(), x => x + 1);
    }),
    formInvalid: Ember.computed("newTitle", "newMedia", "newComment", function () {
      let newTitle = this.get("newTitle");
      let newMedia = this.get("newMedia");
      let newComment = this.get("newComment");
      if (newTitle == "" || newMedia == "" || newComment == "") {
        return true;
      }
    }),
    loadAdPostDiscussionFunc: (0, _emberConcurrency.task)(function* () {
      let adPost = this.get("adPost");
      let result = yield this.get("loadAdPostDiscussions")(adPost.id, this.get("page"), this.get("pageSize"));
      this.set("adPostDiscussions", result.adPostDiscussions);
      this.set("total", result.total);
    }),
    newDiscussionPost: (0, _emberConcurrency.task)(function* () {
      let adP = this.get("adPost");
      yield this.saveNewDiscussion({
        title: this.get("newTitle"),
        media: this.get("newMedia"),
        comment: this.get("newComment"),
        thumbnail: this.get("thumbnail")
      }, adP);
      this.actions.closeDiscussionDialog.apply(this);
      yield adP.reload();
      let latestDiscussion = yield adP.get("latestDiscussion");
      this.set("latestDiscussion", latestDiscussion);
      this.loadAdPostDiscussionFunc.perform(this);
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.loadAdPostDiscussionFunc.perform(this);

      //let adPostId = this.get('adPost').get('id');
      let wflowNum = this.get("ad").get("workFlowStatusNumber");

      //let wfNumAdPostId = wflowNum + "-" + adPostId;

      this.set("wfNum", wflowNum);
      let component = this;
      let adPost = this.get("adPost");
      adPost.get("latestDiscussion").then(function (latestDiscussion) {
        component.set("latestDiscussion", latestDiscussion);
      });
      this.set("compareArray", Ember.A());
      this.set("window", window);
      this.set("showCompareImageDialog", false);
      this.set("showNewDiscussion", false);
      this.set("resembleControl", null);
    },
    actions: {
      setIsImage(aPD, isImage, img) {
        Ember.set(aPD, "isImage", isImage);
        Ember.set(aPD, "img", img);
      },
      setImageToCompareArray(aPD, event) {
        this.compareArray.pushObject({
          aPDid: aPD.id,
          image: aPD.img
        });
        this.notifyPropertyChange("compareArray");
        let elm = event.currentTarget.parentElement;
        this.elm = elm;

        //let point = {x:0,y:elm.offsetHeight}; // Change to y:0 to get the top-left
        let point = {
          x: 0,
          y: 0
        }; // Change to y:0 to get the top-left
        point.x += elm.offsetLeft;
        point.y += elm.offsetTop;

        //point.y -= elm.offsetHeight;

        Ember.run.later(this, function () {
          let compareDiv = document.getElementById("compareDiv");

          //compareDiv.offsetParent.addEventListener("resize", function() {  alert(1); });

          let myPointY = point.y;
          myPointY -= compareDiv.offsetHeight;
          if (compareDiv) {
            compareDiv.style.top = myPointY + "px";
            compareDiv.style.right = "45px";
          }
        }, 60);
      },
      cancelCompareImage() {
        if (confirm("Are you sure to clear the image comparison?")) {
          this.set("compareArray", Ember.A());
          this.notifyPropertyChange("compareArray");
          return;
        }
      },
      startCompareImage() {
        //set popup flag
        this.set("showCompareImageDialog", true);
        Ember.run.later(this, function () {
          let compareImageDialog = document.getElementById("compareImageDialogId");
          compareImageDialog.parentElement.style.maxHeight = "90vh";
          compareImageDialog.scrollIntoView();
          this.resembleControl = (0, _resemble.default)(this.compareArray[0].image.src).outputSettings({
            errorColor: {
              red: 255,
              green: 50,
              blue: 50
            }
          }).compareTo(this.compareArray[1].image.src).onComplete(onComplete);
        }, 300);

        // the end
      },
      closeComapreImageDialog() {
        this.set("showCompareImageDialog", false);
        this.set("compareArray", Ember.A());
        this.notifyPropertyChange("compareArray");
      },
      openNewDiscussion(event) {
        //this.set("dialogOrigin", $(event.currentTarget));
        this.set("dialogOrigin", event.currentTarget);
        this.set("showNewDiscussion", true);
        if (this.latestDiscussion.annoEditor) {
          this.latestDiscussion.set("annoEditor", false);
        }
        // console.log("latestDiscussion id", this.latestDiscussion);

        let adkToast = this.get("adkToast");
        adkToast.display("All fields are required", "warning", 3000);
      },
      closeDiscussionDialog() {
        this.set("showNewDiscussion", false);
        this.set("newTitle", "");
        this.set("newComment", "");
        this.set("newMedia", "");
      },
      uploadAdPostDiscussionPic() {
        return;
      },
      setPage(number) {
        if (number >= this.totalPage) {
          number = this.totalPage - 1;
        } else if (number < 0) {
          number = 0;
        }
        this.set("page", number);
        this.loadAdPostDiscussionFunc.perform(this);
      },
      nextPage() {
        this.actions.setPage.call(this, this.get("page") + 1);
      },
      prevPage() {
        this.actions.setPage.call(this, this.get("page") - 1);
      },
      annoEditor(record) {
        // if(record.annoEditor === true) {
        //     record.set('annoEditor', false);
        // } else {
        //     record.set('annoEditor', true);
        //     record.set('annoViewer', false);
        // }

        record.toggleProperty("annoEditor");
      },
      annoViewer(record) {
        // if(record.annoViewer === true) {
        //     record.set('annoViewer', false);
        // } else {
        //     record.set('annoViewer', true);
        //     record.set('annoEditor', false);
        // }

        record.toggleProperty("annoViewer");
      },
      resembleAction(action, event) {
        let btn = event.currentTarget;
        let parent = btn.parentElement;
        let children = parent.children;
        for (let i = 0; i < children.length; i++) {
          children[i].classList.remove("md-focused");
          children[i].classList.remove("md-raised");
          //let tableChild = children[i];
          // Do stuff
        }
        btn.classList.add("md-raised");
        btn.classList.add("md-focused");
        if (action === "raw") {
          this.resembleControl.ignoreNothing();
        } else if (action === "less") {
          this.resembleControl.ignoreLess();
        }
        if (action === "colors") {
          this.resembleControl.ignoreColors();
        } else if (action === "same-size") {
          this.resembleControl.scaleToSameSize();
        } else if (action === "original-size") {
          this.resembleControl.useOriginalSize();
        } else if (action === "red") {
          this.resembleControl.outputSettings({
            errorColor: {
              red: 255,
              green: 50,
              blue: 50
            }
          }).repaint();
        } else if (action === "green") {
          this.resembleControl.outputSettings({
            errorColor: {
              red: 100,
              green: 255,
              blue: 0
            }
          }).repaint();
        } else if (action === "blue") {
          this.resembleControl.outputSettings({
            errorColor: {
              red: 0,
              green: 100,
              blue: 255
            }
          }).repaint();
        } else if (action === "flat") {
          this.resembleControl.outputSettings({
            errorType: "flat"
          }).repaint();
        } else if (action === "movement") {
          this.resembleControl.outputSettings({
            errorType: "movement"
          }).repaint();
        } else if (action === "flatDifferenceIntensity") {
          this.resembleControl.outputSettings({
            errorType: "flatDifferenceIntensity"
          }).repaint();
        } else if (action === "movementDifferenceIntensity") {
          this.resembleControl.outputSettings({
            errorType: "movementDifferenceIntensity"
          }).repaint();
        } else if (action === "diffOnly") {
          this.resembleControl.outputSettings({
            errorType: "diffOnly"
          }).repaint();
        } else if (action === "opaque") {
          this.resembleControl.outputSettings({
            transparency: 1
          }).repaint();
        } else if (action === "transparent") {
          this.resembleControl.outputSettings({
            transparency: 0.3
          }).repaint();
        }
      }
    }
  });
});