define("adtikir3v1/models/ad-campaign-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2016 adtiki Sdn Bhd. All Rights Reserved.
  // This software is protected by Copyright laws and use is subject to license agreement available at adtiki.com

  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _exports.default = Model.extend({
    //auto tracking means when model is saved/updated/reloaded the tracker will save the current state, 
    //allowing you to rollback anytime
    //changeTracker: { auto: true, only: ['languages', 'targetIndustrySelection'] }, 

    // Updated datetime for backend to ensure last update wins
    updatedAt: attr('date'),
    createdAt: attr('date'),
    action: attr('string', {
      defaultValue: ''
    }),
    // Future use for security
    // signature            : attr('string'),

    customerDesc: attr('string'),
    campaignDesc: attr('string'),
    marketChallenges: attr('string'),
    productServices: attr('string'),
    campaignSuccess: attr('string'),
    // location             : attr(),
    template: attr('boolean'),
    //targeting option
    //created a fg for all target...
    // customNote1             : attr('string'),
    // customNote2             : attr('string'),
    // customNote3             : attr('string'),
    // nationwide           : attr(),

    // targetSet               : attr('boolean', { defaultValue: false }),
    // maleAge                 : array(),
    // femaleAge               : array(),
    // languages               : hasMany('languages-option', { async: true }),
    // teritories              : hasMany('territory-option', { async: true }),
    // countries               : hasMany('country-option', { async: true }),
    // isMarried               : attr('boolean'),
    // hasChildren             : attr('boolean'),
    // incomeLevel             : attr('boolean'),
    // targetIndustry          : attr('boolean'),
    // lowIncome               : attr('boolean'),
    // middleIncome            : attr('boolean'),
    // upperMiddleIncome       : attr('boolean'),
    // highIncome              : attr('boolean'),

    // attachments          : attr(),
    //ads                     : hasMany('ad', { async: true }),
    // campaign workflow - copy from company details. Then each campaign will have own WF

    //acWorkFlows.. cannot get it by relationship as, 
    //it will need to know the campaign id to load the data

    //acWorkFlows           : hasMany('ac-work-flow', {async: true} ),

    // adcampaign             : belongsTo('adcampaign'),
    // adcampaign          : belongsTo('adcampaign', { async: true })

    // invoiceCampaigns        : hasMany('invoiceCampaign'),
    // invoiceAds              : hasMany('invoiceAd')
    targetIndustrySelection: hasMany('industry-type', {
      async: true
    }),
    targeting: belongsTo('targeting', {
      async: true
    }),
    targetCountry: hasMany('targetCountry', {
      async: true
    }),
    languages: hasMany('language', {
      async: true
    })
  });
});