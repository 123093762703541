define("adtikir3v1/pods/campaign/index/route", ["exports", "ember-concurrency", "@jftechnology/ember-keycloak-auth/mixins/keycloak-authenticated-route"], function (_exports, _emberConcurrency, _keycloakAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //route for campaign.index (listing route)
  var _default = _exports.default = Ember.Route.extend(_keycloakAuthenticatedRoute.default, {
    store: Ember.inject.service,
    adkSessionAccount: Ember.inject.service,
    queryParams: {
      page: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      }
    },
    loadModelTask: (0, _emberConcurrency.task)(function* (params) {
      //let adkSessionAccount = this.get('adkSessionAccount');
      try {
        console.time("campaign model get");
        let adCampaigns = yield this.get('store').query('adCampaign', {
          page: params.page,
          pageSize: params.pageSize,
          query: params.query
        }); //query
        console.timeEnd("campaign model get");
        return {
          adCampaigns: adCampaigns
        };
      } catch (e) {
        throw e;
      }
    }).drop(),
    model: function (params) {
      try {
        return this.loadModelTask.perform(params);
      } catch (e) {
        throw e;
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      let applicationController = this.controllerFor('application');
      applicationController.set('isHomeOffice', false);
    },
    resetController: function (controller, isExiting) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('page', 0);
      }
    },
    actions: {
      willTransition: function () {
        this.controller.set('editMode', false);
        this.controller.set('page', 0);
        this.controller.set('query', '');
        this.controller.set('searchText', '');
      } //willTransition
    } //actions
  }); // export default class CampaignIndexRoute extends Route.extend(KeycloakAuthenticatedRouteMixin) {
  //   @service store;
  //   @service adkSessionAccount;
  //   init() {
  //     super.init(...arguments)
  //     this.set('queryParams', {
  //          page: {
  //            refreshModel: true
  //          },    
  //          pageSize: {
  //            refreshModel: true
  //          },
  //     });
  //   }
  //  @task(function* (params) {
  //      //let adkSessionAccount = this.get('adkSessionAccount');
  //     try {
  //        let  adCampaigns = yield this.get('store').query('adCampaign',
  //            {   page: params.page,
  //                pageSize: params.pageSize
  //            }
  //        ); //query
  //        return {
  //          adCampaigns: adCampaigns,
  //        }
  //      } catch (e) {
  //        throw (e);
  //      }
  //   }).drop() loadModelTask;
  //   model(params,transition) {
  //     console.log("trigger",params);
  //     console.log("trigger",transition);
  //     try {
  //       return this.loadModelTask.perform(params);
  //     } catch (e) {
  //       throw (e);
  //     }
  //   }
  //   setupController(controller, model) {
  //     super.setupController(controller, model);
  //   } 
  //   resetController(controller, isExiting, transition) {
  //     if (isExiting) {
  //       // isExiting would be false if only the route's model was changing
  //       controller.set('page', 0);
  //     }
  //   }
  //   actions = {
  //     willTransition: function () {
  //       this.controller.set('editMode', false);
  //     }, //willTransition
  //   } //actions
  // } //export default
});