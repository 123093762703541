define("adtikir3v1/pods/campaign/details/ads/ad/financials/account/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    //need a computed show
    currencyArray: Ember.computed("adCampaign.{currency.foreignCurrency}", function () {
      if (this.get("adCampaign")) {
        let adCampaign = this.get("adCampaign");
        return [adCampaign.currency, adCampaign.foreignCurrency];
      }
      return [];
    }),
    loadApprovedBudgetListTask: (0, _emberConcurrency.task)(function* (page = null, pagingObj) {
      let adId = this.get("ad").get("id");
      let status = pagingObj.status;
      let chargeType = pagingObj.chargeType;
      let result = yield this.store.query("ad-campaign-budget-approved", {
        chargeType: chargeType,
        page: page,
        pageSize: pagingObj.pageSize,
        status: status,
        adId: adId
      });
      Ember.set(pagingObj, "result", result);
      Ember.set(pagingObj, "page", page);
      return true;
    }),
    cleanUp: function () {
      this.set("chargeTypeArray", ["agency", "media", "misc"]);
      for (let i = 0; i < this.chargeTypeArray.length; i++) {
        this.set(this.chargeTypeArray[i] + "BudgetApprovedList", {
          page: 0,
          pageSize: 20,
          action: this.get("loadApprovedBudgetListTask"),
          chargeType: this.chargeTypeArray[i],
          status: "approved",
          result: null,
          refresh: false,
          type: "emberdata"
        });
        this.set(this.chargeTypeArray[i] + "BudgetNotApprovedList", {
          page: 0,
          pageSize: 20,
          action: this.get("loadApprovedBudgetListTask"),
          chargeType: this.chargeTypeArray[i],
          status: "notApproved",
          result: null,
          refresh: false,
          type: "emberdata"
        });
      } //for

      this.reset();
    },
    //cleanUp

    reset() {
      this.set("showAddWindow", false);
      this.set("showReviewWindow", false);
      this.set("showEditWindow", false);
      this.set("showViewWindow", false);
      this.set("showErrorFg", false);
      this.set("adCampaignBudget", null);
      this.set("dialogOrigin", null);
    },
    addeFormReset(chargeType = "") {
      this.set("chargeType", chargeType);
      this.set("title", "");
      this.set("comment", "");
      this.set("amountLocal", 0);
      this.set("amountForeign", 0);
      this.set("currencyType", "local");
      this.set("currency", this.adCampaign.currency);
      this.set("vendorCompany", null);
      this.set("proposalType", "internal");
      this.set("proposalId", "");
      this.set("exchangeRate", 1);
      this.set("exchangeDate", null);
      this.set("exchangeRateType", "campaignRate");
    },
    actions: {
      openAddWindow(chargeType, event) {
        this.set("dialogOrigin", event.currentTarget);
        this.set("showAddWindow", true);
        this.addeFormReset(chargeType);
      },
      closeAddWindow() {
        this.set("dialogOrigin", null);
        this.set("showAddWindow", false);
        this.createFormReset();
      },
      changeCurrency(currency) {
        if (!confirm("this will reset the amount, are you ok?")) {
          return;
        }
        this.set("currency", currency);
        this.set("amountLocal", 0);
        this.set("amountForeign", 0);
        if (currency === this.adCampaign.currency) {
          this.set("currencyType", "local");
        } else if (currency === this.adCampaign.foreignCurrency) {
          this.set("currencyType", "foreign");
        }
      },
      changeAmountForeign(amount) {
        this.set("amountForeign", amount);
        this.set("amountLocal", amount / this.adCampaign.exchangeRate);
      },
      addBudgetForm() {
        //check title
        if (this.title.trim().length === 0) {
          alert("please enter some value for title");
          return;
        }
        //check description
        if (this.comment.trim().length === 0) {
          alert("please enter some value for description");
          return;
        }

        //check amount
        if (this.amountLocal.trim().length === 0) {
          alert("please enter some value for amount");
          return;
        } else if (!this.isNumeric(this.amountLocal)) {
          alert("please enter a numeric value for amount");
          return;
        }
        if (!(this.chargeType === "misc" || this.chargeType === "agency" || this.chargeType === "media")) {
          alert("please select the correct charge type");
          return;
        }

        //save to db
        let committedBudgetApproved = this.store.createRecord("ad-campaign-budget-approved", {
          amountLocal: this.amountLocal,
          amountForeign: this.amountForeign,
          comment: this.comment,
          title: this.title,
          proposalType: "",
          proposalId: null,
          currencyType: this.currencyType,
          currency: this.currency,
          exchangeRate: this.adCampaign.exchangeRate,
          exchangeRateDate: new Date(),
          excjamgeRateType: "campaignRate",
          status: "new",
          chargeType: this.chargeType,
          createdAt: new Date(),
          updatedAt: new Date(),
          firstName: "",
          lastName: "",
          UserRoleId: "",
          role: "",
          vendorCompany: null
        });
        committedBudgetApproved.set("ad", this.ad);
        committedBudgetApproved.set("adCampaign", this.adCampaign);
        let component = this;
        committedBudgetApproved.save().then(function () {
          alert("record saved");
          //component.createFormReset();
          //component.set("showAddWindow", false);
          component.actions.closeAddWindow.apply(component);
        });
      } //addBudgetForm
    }
  });
});