define("adtikir3v1/pods/template/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    templateType: '',
    init() {
      this._super(...arguments);
      this.set('templateType', '');
    },
    //init

    // somple first.. do select from currently role/company first...
    // later add on territory office and home office

    //step 1 
    //    choose template type
    //step 2
    //    ad template, choose campaign/ad [ only confirmed (stage 2 to 8) ad will be allowed ]
    //    import info from ad
    //    save

    //   camapign template
    //   only comfirm template will be allowed
    //   import campaign info
    //   choose  ads from campaign  [ only confirmed ad with stage 2 to 8 woll be allowed ]
    //   import ad info
    //   save

    actions: {
      saveCampaign() {},
      selectTemplateType(type) {
        this.set("templateType", type);
        this.transitionToRoute('template.new.ad');
      }
    } //actions
  });
});