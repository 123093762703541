define("adtikir3v1/pods/components/adk-budget-ultimate/component", ["exports", "ember-concurrency", "sanitize-html", "moment"], function (_exports, _emberConcurrency, _sanitizeHtml, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    adkToast: Ember.inject.service(),
    adkSessionAccount: Ember.inject.service(),
    comment: '',
    // showApproval: false,

    init() {
      this._super(...arguments);
      this.setProperties({
        companyExchangeRateType: this.get('adkSessionAccount.myCompany.exchangeRateType'),
        companyExchangeRateRate: this.get('adkSessionAccount.myCompany.exchangeRate'),
        companyExchangeRateDate: (0, _moment.default)(this.get('adkSessionAccount.myCompany.exchangeRateDate')).format('YYYY-MM-DD')
      });
      this.exchangeRateOptions = [{
        value: "daily",
        name: "Daily Exchange Rate"
      }, {
        value: "monthly",
        name: "Last Month Monthly Exchange Rate"
      },
      // { value:"rolling", name:"Rolling Monthly Exchange Rate"},
      {
        value: "annual",
        name: "Annual Exchange Rate (Previous Year)"
      }];
      this.exchangeRateWithCustomOptions = this.exchangeRateOptions.concat();
      this.exchangeRateWithCustomOptions[3] = {
        value: "custom",
        name: "Company Exchange Rate"
      };
      this.getExchangeRate.perform();
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.setProperties({
        //adCampaign: this.get('adCampaign'),
        //ads: this.get('ads'),
        campaignUltimateBudget: this.adCampaign.get('campaignUltimateBudget'),
        //adTotalMediatype: this.get('adTotalMediatype'),
        // campaignCommitted: this.adCampaign.get('campaignCommitted'),
        campaignActualSpend: this.adCampaignBudget.get('campaignActualSpend'),
        newBudgetUltimate: '',
        localCurrency: this.adCampaign.get('currency'),
        foreignCurrency: this.adCampaign.get('foreignCurrency'),
        exchangeRateType: this.adCampaign.get('exchangeRateType'),
        exchangeRateDate: this.adCampaign.get('exchangeRateDate')
        //obsoleted
        // campaignBudget:            this.adCampaignBudget.get('campaignBudget'),
        // campaignPlanned:           this.adCampaignBudget.get('campaignPlanned'),
      });
      // this.getExchangeRate.perform();
    },
    getExchangeRate: (0, _emberConcurrency.task)(function* () {
      let adCampaign = this.get('adCampaign');
      let companyExchangeRateType = this.get('companyExchangeRateType');
      let exchangeRateOptions = this.get('exchangeRateOptions');
      let exchangeRateWithCustomOptions = this.get('exchangeRateWithCustomOptions');
      let dailyExchangeRateId = adCampaign.currency.concat('to').concat(adCampaign.foreignCurrency);
      let monthlyExchangeRateId = adCampaign.currency.concat('to').concat(adCampaign.foreignCurrency).concat('-monthly');
      let annualExchangeRateId = adCampaign.currency.concat('to').concat(adCampaign.foreignCurrency).concat('-annual');
      let dailyExchangeRateObj = yield this.store.findRecord('exchange-rate', dailyExchangeRateId);
      let monthlyExchangeRateObj = yield this.store.findRecord('exchange-rate', monthlyExchangeRateId);
      let annualExchangeRateObj = yield this.store.findRecord('exchange-rate', annualExchangeRateId);
      let companyExchangeRateObj = {
        exchangeRate: this.get('companyExchangeRateRate'),
        exchangeDateStr: this.get('companyExchangeRateDate')
      };
      this.set('dailyExchangeRateObj', dailyExchangeRateObj);
      this.set('monthlyExchangeRateObj', monthlyExchangeRateObj);
      this.set('annualExchangeRateObj', annualExchangeRateObj);
      this.set('companyExchangeRateObj', companyExchangeRateObj);
      let rateArray = ['dailyExchangeRateObj', 'monthlyExchangeRateObj', 'annualExchangeRateObj'];
      if (companyExchangeRateType == "custom") {
        let rateArrayWithCustom = rateArray.concat();
        rateArrayWithCustom[3] = 'companyExchangeRateObj';
        for (let i = 0; i < exchangeRateWithCustomOptions.length; i++) {
          Ember.set(exchangeRateWithCustomOptions[i], 'name', exchangeRateWithCustomOptions[i].name.concat(" (" + this.get(rateArrayWithCustom[i]).exchangeRate + ', ' + this.get(rateArrayWithCustom[i]).exchangeDateStr + ")"));
        }
      } else {
        for (let i = 0; i < exchangeRateOptions.length; i++) {
          Ember.set(exchangeRateOptions[i], 'name', exchangeRateOptions[i].name.concat(" (" + this.get(rateArray[i]).exchangeRate + ', ' + this.get(rateArray[i]).exchangeDateStr + ")"));
        }
      }
    }),
    saveExchangeRateType: (0, _emberConcurrency.task)(function* () {
      let adkToast = this.get('adkToast');
      let adCampaign = this.get('adCampaign');
      try {
        let oldExchangeRateFormatted = parseFloat(adCampaign.exchangeRate).toFixed(4);
        let newExchangeRateFormatted = parseFloat(this.newExchangeRate).toFixed(4);
        let oldExchangeRateDate = adCampaign.exchangeRateDate;
        let newExchangeRateDate = this.get('newExchangeRateDate');
        adCampaign.set('action', "updateExchangeRate");
        adCampaign.set('exchangeRateType', this.get('exchangeRateType'));
        adCampaign.set('exchangeRate', newExchangeRateFormatted);
        adCampaign.set('exchangeRateDate', newExchangeRateDate);
        yield adCampaign.save();
        yield adCampaign.reload();
        let newComment = (0, _sanitizeHtml.default)(this.newExchangeRateComment);
        const exchangeRateComment = this.store.createRecord('budgetUltimateComment', {
          comment: newComment,
          oldValue: oldExchangeRateFormatted,
          newValue: newExchangeRateFormatted,
          type: "e",
          adCampaignId: adCampaign.id
        });
        exchangeRateComment.set('exchangeRateType', this.get('exchangeRateType'));
        exchangeRateComment.set('oldExchangeRateDate', oldExchangeRateDate);
        exchangeRateComment.set('newExchangeRateDate', newExchangeRateDate);
        yield exchangeRateComment.save();
        adkToast.display("Exchange rate type successfully saved.", "warning");
        this.set('showExchangeRateDialog', false);
        this.set('newExchangeRateComment', '');
      } catch (e) {
        adkToast.display("Fail to update exchange rate", "error");
      }
    }),
    openExchangeRateDialog: (0, _emberConcurrency.task)(function* () {
      // this.set('dialogOrigin', event.currentTarget);
      this.set('showExchangeRateDialog', true);
      let adCampaign = this.get('adCampaign');
      let exchangeRateComment = yield this.store.query('budgetUltimateComment', {
        'adCampaign': adCampaign.id,
        'type': 'e'
      });
      this.set('exchangeRateComment', exchangeRateComment);
    }),
    selectedExchangeRateType: Ember.computed("exchangeRateType", function () {
      return this.get('exchangeRateWithCustomOptions').findBy('value', this.get('exchangeRateType'));
    }),
    availableBudget: Ember.computed('campaignUltimateBudget', 'campaignPlanned', function () {
      return this.get('campaignUltimateBudget') - this.get('campaignPlanned');
    }),
    campaignPlanned: Ember.computed('adTotalMediatype', function () {
      const total = this.get('adTotalMediatype').reduce((currentTotal, mediatype) => {
        return mediatype.plannedTotal + currentTotal;
      }, 0);
      return total;
    }),
    campaignCommitted: Ember.computed('adTotalMediatype', function () {
      const total = this.get('adTotalMediatype').reduce((currentTotal, mediatype) => {
        return mediatype.committedTotal + currentTotal;
      }, 0);
      return total;
    }),
    campaignActuals: Ember.computed('adTotalMediatype', function () {
      const total = this.get('adTotalMediatype').reduce((currentTotal, mediatype) => {
        return mediatype.actualTotal + currentTotal;
      }, 0);
      return total;
    }),
    totalEstimate: 0,
    budgetWarning: Ember.computed('adcampaign.campaignBudgetAvailable', function () {
      let campaignBudgetAvailable = this.get('adcampaign.campaignBudgetAvailable');
      if (campaignBudgetAvailable < 0) {
        return true;
      }
      return false;
    }),
    actions: {
      closeErr() {
        this.set('showErrorFg', false);
      },
      openDialogWithParent(event) {
        this.set('dialogOrigin', event.currentTarget);
        this.set('showDialogWithParent', true);
        let adCampaign = this.get('adCampaign');
        let budgetUltimateComment = this.store.query('budgetUltimateComment', {
          'adCampaign': adCampaign.id,
          'type': 'b'
        });
        this.set('budgetUltimateComment', budgetUltimateComment);
      },
      closeExchangeRateDialog() {
        this.set("exchangeRateType", this.get('adCampaign').get('exchangeRateType'));
        this.set('showExchangeRateDialog', false);
        this.set('newExchangeRateComment', '');
      },
      updateExchangeRateType(selectedType) {
        this.set('exchangeRateType', selectedType.value);
        let dailyExchangeRateObj = this.get('dailyExchangeRateObj');
        let monthlyExchangeRateObj = this.get('monthlyExchangeRateObj');
        let annualExchangeRateObj = this.get('annualExchangeRateObj');
        if (selectedType.value == "custom") {
          this.set("newExchangeRate", this.get('companyExchangeRateRate'));
          this.set("newExchangeRateDate", this.get('companyExchangeRateDate'));
        } else if (selectedType.value == "daily") {
          this.set('newExchangeRate', dailyExchangeRateObj.exchangeRate);
          this.set('newExchangeRateDate', dailyExchangeRateObj.exchangeDateStr);
        } else if (selectedType.value == "monthly") {
          this.set('newExchangeRate', monthlyExchangeRateObj.exchangeRate);
          this.set('newExchangeRateDate', monthlyExchangeRateObj.exchangeDateStr);
        } else if (selectedType.value == "annual") {
          this.set('newExchangeRate', annualExchangeRateObj.exchangeRate);
          this.set('newExchangeRateDate', annualExchangeRateObj.exchangeDateStr);
        }
      },
      async budgetUltimateCommentSave() {
        // event.preventDefault();

        let adkToast = this.get('adkToast');
        this.toggleProperty('isEditing');
        //      let controller = this;
        let adCampaign = this.get('adCampaign');
        try {
          let newBudgetUltimateFormatted = parseFloat(this.newBudgetUltimate).toFixed();
          let newComment = (0, _sanitizeHtml.default)(this.comment);
          const budgetUltimateComment = this.store.createRecord('budgetUltimateComment', {
            comment: newComment,
            newValue: newBudgetUltimateFormatted,
            adCampaignId: adCampaign.id,
            type: "b"
          });
          budgetUltimateComment.set('oldValue', adCampaign.campaignUltimateBudget);
          await budgetUltimateComment.save();
          adkToast.display("Comment successfully saved.", "warning");

          //Refresh model to show new ultimates
          await adCampaign.reload();
          this.set('campaignUltimateBudget', adCampaign.get('campaignUltimateBudget'));
          // budgetUltimateComment.reload();

          this.set('comment', '');
          this.set('newBudgetUltimate', '');
          this.set('showDialogWithParent', false);
        } catch (err) {
          this.set('comment', '');
          this.set('newBudgetUltimate', '');
          this.set('showDialogWithParent', false);
          adkToast.display("Failed to save budget", "error");
          throw new Error(err);
        }
      },
      closeDialogWithParent() {
        // this.set("exchangeRateType", this.get('adCampaign').get('exchangeRateType'));
        this.set('newBudgetUltimate', '');
        this.set('comment', '');
        this.set('showDialogWithParent', false);
      },
      toggleEdit() {
        this.toggleProperty('isEditing');
      },
      componentSaveBudget() {
        this.toggleProperty('isEditing');
        this.campaignSaveBudget({
          campaignBudget: this.get('campaignBudget'),
          campaignPlanned: this.get('campaignPlanned')
        }); //End of localComponentSaveName
      }
    }
  });
});