define("adtikir3v1/pods/components/adk-template-ad-new/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import adkDateTime from '../../../utils/adk-date-time';
  // import { task } from 'ember-concurrency';
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    //inject store to be able to use it

    init() {
      this._super(...arguments);
      // this.ad=this.args.ad;
      //let ad = this.ad;
      this.selectedBasicTab = 0;

      // this.adName = ad.adName;
      // this.adName = ad.adName;
      // this.adName = ad.adName;
      // this.adName = ad.adName;

      this.numberValidation = [{
        message: 'Please provide a valid value (99999999.99)',
        validate: inputValue => {
          let valuePattern = /^\d+(\.\d{1,2})?$/;
          return valuePattern.test(inputValue);
        }
      }];
      this.set('palette', [["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"], ["#f00", "#f90", "#ff0", "#0f0", "#0ff", "#00f", "#90f", "#f0f"], ["#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#cfe2f3", "#d9d2e9", "#ead1dc"], ["#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#9fc5e8", "#b4a7d6", "#d5a6bd"], ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"], ["#c00", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3d85c6", "#674ea7", "#a64d79"], ["#900", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#0b5394", "#351c75", "#741b47"], ["#600", "#783f04", "#7f6000", "#274e13", "#0c343d", "#073763", "#20124d", "#4c1130"]]);
      this.startComp();
    },
    //init

    startComp() {
      let ad = this.ad;
      this.adName = ad.adName;
      this.adNameLocal = ad.adNameLocal;
      this.description = ad.description;
      this.adColor = ad.adColor;
      this.adPic = ad.adPic;
      let adPlayStartDate = (0, _moment.default)(ad.adPlayStartDate, "YYYY-MM-DD");
      let adPlayEndDate = (0, _moment.default)(ad.adPlayEndDate, "YYYY-MM-DD");
      this.adDiffDays = adPlayEndDate.diff(adPlayStartDate, 'days');
      this.set('mediatype', ad.mediatype);
      if (this.mediatype) {
        let thisComponent = this;
        this.store.findRecord('media-type', this.mediatype).then(function (result) {
          thisComponent.set('selectedMediaType', result);
        });
      }
      this.set('mediaformat', ad.mediaformat);
      if (this.mediaformat) {
        let thisComponent = this;
        this.store.findRecord('media-format', this.mediaformat).then(function (result) {
          thisComponent.set('selectedMediaFormat', result);
        });
      }
      this.set('adMediaFormatDetails', ad.adMediaFormatDetails);
      //
    },
    //startComp

    mediaFormatArray: Ember.computed('selectedMediaType', function () {
      let returnVal = [];
      let mediaType = this.get('selectedMediaType');
      if (mediaType) {
        returnVal = mediaType.get('mediaFormats').toArray();
      }
      return returnVal;
    }),
    //mediaFormatArray..

    actions: {
      selectMediaTypeImage: function (itemModel) {
        this.set('selectedMediaType', itemModel);
        this.set('mediaTypeName', itemModel.mediaTypeName);
        this.set('selectedMediaFormat', '');
      },
      selectMediaFormatImage: function (imageName, itemModel) {
        this.set('mediaFormat', itemModel);
      }
    }
  });
});