define("adtikir3v1/pods/components/adk-flowise-ui/component", ["exports", "adtikir3v1/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //either this will work
  //import config from 'adtikir3v1/config/environment';
  var _default = _exports.default = Ember.Component.extend({
    showDialog: false,
    queryStr: "",
    mouseDownFlag: false,
    mouseMoveFlag: false,
    contextMenuFlag: false,
    showCustomRightClickBind: null,
    adkMouseDownBind: null,
    adkMouseMoveBind: null,
    rightClickContent: '',
    init() {
      this._super(...arguments);
      //event will be added in template
      this.adkMouseDownBind = this.adkMouseDown.bind(this);
    },
    fQuery: async function (data) {
      const response = await fetch(_environment.default.flowiseURL, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + _environment.default.flowiseBareerToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      return result;
    },
    removeRightClickEvent(thisObj) {
      if (thisObj.showCustomRightClickBind) {
        document.removeEventListener("contextmenu", thisObj.showCustomRightClickBind);
        thisObj.showCustomRightClickBind = null;
        thisObj.contextMenuFlag = false;
      } //if
    },
    removeClickEvent(thisObj) {
      if (thisObj.hideCustomRightClickBind) {
        document.removeEventListener("click", thisObj.hideCustomRightClickBind, false);
        thisObj.hideCustomRightClickBind = null;
      }
    },
    hideCustomRightClick() {
      document.getElementById("rmenu").className = "adkHideRightClick";
      document.removeEventListener("click", this.hideCustomRightClickBind, false);
    },
    showCustomRightClick(event) {
      // Check if there's a selection on the page
      let selObj;
      if (window.getSelection) {
        selObj = window.getSelection();
      } else if (document.selection) {
        selObj = document.selection;
      }
      if (selObj.toString()) {
        // Show the custom menu at the cursor location if text is selected
        event.preventDefault(); // Prevent the default context menu
        document.getElementById("rmenu").className = "adkShowRightClick";
        document.getElementById("rmenu").style.top = this.mouseY(event) + "px";
        document.getElementById("rmenu").style.left = this.mouseX(event) + "px";
        this.rightClickContent = event.currentTarget;
        //need add event to remove showed menu...
        this.hideCustomRightClickBind = this.hideCustomRightClick.bind(this);
        document.addEventListener("click", this.hideCustomRightClickBind, false);
        window.event.returnValue = false;
      } else {
        //console.log("why no call me");
        //      setTimeout(this.removeMyEvent, 200, this);
      }
    },
    //check if mouse button is down
    adkMouseDown(event) {
      this.mouseDownFlag = true;
      if (event.which === 1) {
        //left click
        this.adkMouseUpBind = this.adkMouseUp.bind(this);
        this.adkMouseMoveBind = this.adkMouseMove.bind(this);
        event.currentTarget.addEventListener("mousemove", this.adkMouseMoveBind);
        event.currentTarget.addEventListener("mouseup", this.adkMouseUpBind);
      } else if (event.which === 3) {
        //do nothing first
      } //if event.witch === 3
    },
    //detech mouse move when mouse down.. mean is a drag...
    adkMouseMove(event) {
      //console.log("event move", event.which);
      if (event.which === 1 && !this.mouseMoveFlag && this.mouseDownFlag) {
        this.mouseMoveFlag = true;
        //this.preventDefault();
      }
    },
    //check if mouse button is release
    adkMouseUp(event) {
      if (event.which === 1) {
        if (this.mouseMoveFlag) {
          this.mouseMoveFlag = false;
          //console.log(event.currentTarget);
          if (!this.contextMenuFlag) {
            this.showCustomRightClickBind = this.showCustomRightClick.bind(this);
            document.addEventListener("contextmenu", this.showCustomRightClickBind);
            this.contextMenuFlag = true;
          }
        }
        //if mouse click is down.. then remove the event
        if (this.mouseDownFlag) {
          event.currentTarget.removeEventListener("mousemove", this.adkMouseMoveBind);
          this.adkMouseMoveBind = null;
          event.currentTarget.removeEventListener("mousemove", this.adkMouseUpBind);
          this.adkMouseUpBind = null;
          this.mouseDownFlag = false;
        }
      } // if (event.which === 1)
    },
    //function to create humanMessage text
    createHumanMessage: function () {
      let text = "####\n";
      text += "Campaign Name:" + this.adCampaign.campaignName + "\n\n";
      text += "Campaign Description:\n" + this.adCampaignDetail.campaignDesc + "\n\n";
      text += "Customer Description:\n" + this.adCampaignDetail.customerDesc + "\n\n";
      text += "Market Challenges:\n" + this.adCampaignDetail.marketChallenges + "\n\n";
      text += "Product Services:\n" + this.adCampaignDetail.productServices + "\n\n";
      text += "Campaign Success:\n" + this.adCampaignDetail.campaignSuccess + "\n\n";

      //maybe need to target inductry here
      //maybe need to do targeting here
      //maybe need to do targetCountry
      //maybe need to do laguages

      text += "####\n\n";

      // text += "Question: {user_question}"

      return text;
    },
    mouseX(evt) {
      if (evt.pageX) {
        return evt.pageX;
      } else if (evt.clientX) {
        return evt.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft);
      } else {
        return null;
      }
    },
    mouseY(evt) {
      let obj = evt.target;
      let offsetTop = 0;
      if (obj.offsetParent) {
        do {
          let style = window.getComputedStyle(obj);
          if (style.position === "relative" && obj.classList.contains("liquid-container")) {
            offsetTop = obj.offsetTop;
            break;
          }
        } while (obj = obj.offsetParent);
      }

      //return evt.clientY + window.pageYOffset
      if (evt.pageY) {
        return evt.pageY - offsetTop;
      } else if (evt.clientY) {
        return evt.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop) - offsetTop;
      } else {
        return null;
      }
    },
    actions: {
      openNewAI(event) {
        this.set("showDialog", true);
        this.set("buttonId", event.currentTarget.id);
        this.set("queryStr", "hi");
        this.set("lastResponse", null);
        this.set("mouseDownFlag", false);
        this.set("mouseMoveFlag", false);
        this.set("contextMenuFlag", false);
        this.set("adkMouseUpBind", null);
        this.set("adkMouseMoveBind", null);
        this.set("showCustomRightClickBind", null);
        this.set("hideCustomRightClickBind", null);
        this.set("rightClickContent", '');
        setTimeout(() => {
          //console.log(document.getElementById("AIQueryInput").children[0]);
          document.getElementById("AIQueryInput").children[0].focus();
          document.getElementById("AIQueryInput").parentElement.children[2].click();
        }, 700);
      },
      closeDialog() {
        this.set("lastResponse", null);
        this.set("queryStr", "");
        this.set("showDialog", false);
        this.set("mouseDownFlag", false);
        this.set("mouseMoveFlag", false);
        this.set("contextMenuFlag", false);
        this.set("adkMouseUpBind", null);
        this.set("adkMouseMoveBind", null);

        //cloese all listeners
        this.removeRightClickEvent(this);
        this.removeClickEvent(this);

        //this.set("showCustomRightClickBind", null);
        //this.set("hideCustomRightClickBind", null);

        this.set("rightClickContent", '');
      },
      checkEnter(event) {
        if (event.key === "Enter") {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          event.currentTarget.parentElement.nextElementSibling.nextElementSibling.click();
        }
      },
      sendQuery(event) {
        if (!this.queryStr) {
          return;
        }
        let contentDiv = event.currentTarget.parentElement.parentElement.children[0].children[1];
        let thisComponent = this;
        this.fQuery({
          question: this.queryStr,
          overrideConfig: {
            sessionId: thisComponent.lastResponse ? thisComponent.lastResponse.sessionId : Date.now(),
            humanMessagePrompt: {
              seqAgent_0: this.createHumanMessage() + "\n{question}\n"
            },
            vars: {
              topic: this.topic,
              campaignName: this.adCampaign.campaignName
            }
          }
        }).then(response => {
          thisComponent.set("lastResponse", response);
          let breakTag = "<br>"; // Adjust comment to avoid issue on phpjs.org display
          let strQuestion = (" " + response.question).slice(1).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
          let strText = (" " + response.text).slice(1).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
          contentDiv.insertAdjacentHTML("beforeend", "<hr>\n<p><strong style=\"font-size:20px\">" + strQuestion + "</strong><br>" + strText + "</p>");
          thisComponent.set("queryStr", "");
          setTimeout(() => {
            contentDiv.parentElement.scrollTo({
              top: contentDiv.scrollHeight,
              behavior: 'smooth'
            });
          }, 200); // delay for 1 second
        }); //then
      },
      //sendQuery

      //appendContent
      appendContent() {
        let selObj;
        if (window.getSelection) {
          selObj = window.getSelection();
        } else if (document.selection) {
          selObj = document.selection;
        }
        selObj.toString();

        //console.log(this.adCampaignDetail[this.fieldName]);
        this.adCampaignDetail[this.fieldName] += "\n<br><br>\n" + selObj.toString();
      },
      //appendContent

      overWriteContent() {
        let selObj;
        if (confirm("Are you sure to overwrite the content?")) {
          if (window.getSelection) {
            selObj = window.getSelection();
          } else if (document.selection) {
            selObj = document.selection;
          }
          selObj.toString();

          //console.log(this.adCampaignDetail[this.fieldName]);
          this.adCampaignDetail[this.fieldName] = selObj.toString();
        } //confirm
      },
      //overWriteContent

      cancelMenu(event) {
        this.hideCustomRightClick(event);
      }
    } //actions
  }); // langsmith key for adtiki
  // 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
});