define("adtikir3v1/pods/components/adk-image-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { inject as service} from '@ember/service';
  //import { task} from 'ember-concurrency';
  // import config from '../../../config/environment';
  var _default = _exports.default = Ember.Component.extend({
    //Soon - set the selected graphic in a range of graphic images.

    didReceiveAttrs: function () {
      this.set('selectedGraphic', '');
      this.set('selectedGraphicIndex', '');

      //the function called is substituited when specified in calling hbs selectedImage = (action 'functionName')
    },
    actions: {
      // imageURL is the url of the images
      // index is the index of the array where the image was picked
      // name is the description
      // itemModel is the entire model, a class

      selectImage: function (imageURL, index, itemModel) {
        //Highlights the selected Images
        this.set('selectedGraphic', imageURL);
        this.set('selectedGraphicIndex', index);
        this.set('selectedItem', itemModel);
        //Calls the parent action to update the graphicImage and
        //the function called is substituited when specified in calling hbs selectedImage = (action 'functionName')
        this.get('selectedImage')(itemModel);
        // console.log('kelvin', imageURL, index)
        // console.log('***************')
        // console.log(itemModel)
      }
    }
  });
});